import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore, useAlertStore, useMeldingStore } from '@/stores';
import StartView from '../views/StartView.vue'
import HomeView from '../views/HomeView.vue'
import AddMeldingView from '../views/AddMeldingView.vue'
import AddMeldingDoelgroepView from '../views/AddMeldingDoelgroepView.vue'
import AddMeldingOpslaanView from '../views/AddMeldingOpslaanView.vue'
import AddMeldingOpvolgenView from '../views/AddMeldingOpvolgenView.vue'
import AddMeldingLeefgebiedView from '../views/AddMeldingLeefgebiedView.vue'
import AddMeldingContactgegevensView from '../views/AddMeldingContactgegevensView.vue'
import AddSchoolrondeView from '../views/AddSchoolrondeView.vue'
import AddWijkrondeView from '../views/AddWijkrondeView.vue'
import TodayView from '../views/TodayView.vue'
import MeldingDetailView from '../views/MeldingDetailView.vue'
import MeldingEditView from '../views/MeldingEditView.vue'
import ProfileView from '../views/ProfileView.vue'

const routes = [
  {
    path: '/',
    name: 'default',
    component: StartView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      headerTitle: 'Start',
      transition: '',
      footer: true
    }
  },
  {
    path: '/home/:alert',
    name: 'home-with-alert',
    component: HomeView,
    meta: {
      headerTitle: 'Start',
      transition: '',
      footer: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: {
      header: false
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: {
      headerTitle: 'Profiel',
      transition: '',
      footer: true
    }
  },
  {
    path: '/today',
    name: 'today',
    component: TodayView,
    meta: {
      headerTitle: 'Vandaag',
      transition: '',
      footer: true
    }
  },
  {
    path: '/melding-detail/:meldingId',
    name: 'melding-detail',
    component: MeldingDetailView,
    meta: {
      headerTitle: 'Melding',
      transition: 'slide'
    }
  },
  {
    path: '/melding-edit',
    name: 'melding-edit',
    component: MeldingEditView,
    meta: {
      headerTitle: 'Melding',
      cancelMeldingEditEnabled: true,
      saveMeldingEditEnabled: true,
      transition: 'slide'
    }
  },
  {
    path: '/permissions',
    name: 'permissions',
    component: () => import('../views/PermissionsView.vue')
  },
  {
    path: '/install',
    name: 'install',
    component: () => import('../views/InstallView.vue')
  },
  {
    path: '/install-successful',
    name: 'install-successful',
    component: () => import('../views/InstallSuccessfulView.vue')
  },
  {
    path: '/install-failed',
    name: 'installFailed',
    component: () => import('../views/InstallFailedView.vue')
  },
  {
    path: '/add-melding',
    name: 'add-melding',
    component: AddMeldingView,
    meta: {
      headerTitle: 'Nieuwe melding',
      cancelMeldingEnabled: true,
      transition: 'slide-up'
    }
  },
  {
    path: '/add-melding/wijkronde',
    name: 'add-wijkronde',
    component: AddWijkrondeView,
    meta: {
      cancelMeldingEnabled: true,
      saveMeldingEnabled: true,
      headerTitle: 'Wijkronde',
      transition: 'slide'
    }
  },
  {
    path: '/add-melding/schoolronde',
    name: 'add-schoolronde',
    component: AddSchoolrondeView,
    meta: {
      cancelMeldingEnabled: true,
      saveMeldingEnabled: true,
      headerTitle: 'Schoolronde',
      transition: 'slide'
    }
  },
  {
    path: '/add-melding/doelgroep',
    name: 'add-melding-doelgroep',
    component: AddMeldingDoelgroepView,
    meta: {
      cancelMeldingEnabled: true,
      transition: 'slide'
    }
  },
  {
    path: '/add-melding/leefgebied',
    name: 'add-melding-leefgebied',
    component: AddMeldingLeefgebiedView,
    meta: {
      cancelMeldingEnabled: true,
      transition: 'slide'
    }
  },
  {
    path: '/add-melding/opslaan',
    name: 'add-melding-opslaan',
    component: AddMeldingOpslaanView,
    meta: {
      cancelMeldingEnabled: true,
      saveMeldingEnabled: true,
      transition: 'slide'
    }
  },
  {
    path: '/add-melding/opvolgen',
    name: 'add-melding-opvolgen',
    component: AddMeldingOpvolgenView,
    meta: {
      cancelMeldingEnabled: true,
      saveMeldingEnabled: false,
      sendMeldingEnabled: false,
      transition: 'slide'
    }
  },
  {
    path: '/add-melding/contactgegevens',
    name: 'add-melding-contactgegevens',
    component: AddMeldingContactgegevensView,
    meta: {
      cancelMeldingEnabled: true,
      saveMeldingEnabled: false,
      sendMeldingEnabled: true,
      transition: 'slide'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
    // clear alert on route change
    const alertStore = useAlertStore();
    alertStore.clear();
    
    // redirect to login page if not logged in and trying to access a restricted page 
    const publicPages = ['/', '/login', '/permissions', '/install', '/install-successful', '/install-failed'];
    const authRequired = !publicPages.includes(to.path);
    
    const authStore = useAuthStore();
    if (authRequired && !authStore.user) {
        authStore.returnUrl = to.fullPath;
        return '/login';
    }
});

router.afterEach((to, from) => {
  const meldingStore = useMeldingStore();
  if (meldingStore.isCancelled) {
    to.meta.transition = 'slide-down'
    to.meta.mode = 'out-in'
    meldingStore.isCancelled = false;
  }

  if (meldingStore.moveBack || from.fullPath.indexOf('detail') != -1) {
    to.meta.transition = 'slide-left'
    meldingStore.moveBack = false;
  }

  if (to.fullPath.indexOf('doelgroep') != -1 || to.fullPath.indexOf('leefgebied') != -1 || to.fullPath.indexOf('opslaan') != -1) {
    var currentMelding = meldingStore.getCurrentMelding();
    if (currentMelding.typeMelding == 11) {
      to.meta.headerTitle = 'Praatje / Vraag';
    } 
    else {
      to.meta.headerTitle = 'Signaal / Probleem';
    }
  }
})

export default router
