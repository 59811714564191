<template>
  <div class="container-md mt-4 text-center">
    <p class="melding">Klik op 'Opslaan' om de huidige locatie te markeren.</p>
    
    <nav class="navbar navbar-expand fixed-bottom">
      <div class="container-fluid">
        <div class="navbar-nav d-flex justify-content-evenly">
          <a class="btn btn-secondary" href="#" @click="previous()">Vorige</a>
        </div>
      </div>
    </nav>
    
    <BorderSpinner :isVisible="isSaving" />
  </div>
</template>

<script>
import BorderSpinner from '@/components/BorderSpinner.vue'
import { useMeldingStore } from '@/stores';

export default {
  name: 'AddSchoolrondeView',
  components: {
    BorderSpinner
  },
  data() {
    return {
      isSaving: false
    }
  },
  methods: {
    previous() {
      const meldingStore = useMeldingStore();
      meldingStore.cancelCurrentMelding();
      
      this.$router.push('/add-melding')
    },
    
  }
}
</script>

<style scoped lang="less">
@import "../styles/variables.less";

.navbar {
  .navbar-nav {
    padding-bottom: calc(env(safe-area-inset-bottom) + 26px);
  }
}
</style>