<template>
  <div class="container-md mt-4">

    <h3>Leefgebied</h3>

    <form v-on:submit.prevent="onSubmit">
      <div class="list-group">
        <a href="#" class="list-group-item list-group-item-action" @click="select('Wonen & woonomgeving')">Wonen & woonomgeving</a>
        <a href="#" class="list-group-item list-group-item-action" @click="select('Relatie & gezin')">Relatie & gezin</a>
        <a href="#" class="list-group-item list-group-item-action" @click="select('Psychische gezondheid')">Psychische gezondheid</a>
        <a href="#" class="list-group-item list-group-item-action" @click="select('Praktisch functioneren')">Praktisch functioneren</a>
        <a href="#" class="list-group-item list-group-item-action" @click="select('Zingeving')">Zingeving</a>
        <a href="#" class="list-group-item list-group-item-action" @click="select('Veiligheid')">Veiligheid</a>
        <a href="#" class="list-group-item list-group-item-action" @click="select('Financiën')">Financi&euml;n</a>
        <a href="#" class="list-group-item list-group-item-action" @click="select('Maatschappelijke participatie')">Maatschappelijke participatie</a>
        <a href="#" class="list-group-item list-group-item-action" @click="select('Sociaal netwerk (& eenzaamheid)')">Sociaal netwerk (& eenzaamheid)</a>
        <a href="#" class="list-group-item list-group-item-action" @click="select('Lichamelijke gezondheid')">Lichamelijke gezondheid</a>
        <a href="#" class="list-group-item list-group-item-action" v-if="melding.typeMelding == 11" @click="select('Sociaal praatje')">Sociaal praatje</a>
      </div>
    </form>
    
    <nav class="navbar navbar-expand fixed-bottom">
      <div class="container-fluid">
        <div class="navbar-nav d-flex justify-content-evenly">
          <a class="btn btn-secondary" href="#" @click="previous()">Vorige</a>
        </div>
      </div>
    </nav>
  </div>
  
</template>

<script>
import { useMeldingStore } from '@/stores';

export default {
  name: 'AddMeldingLeefgebiedView',
  data() {
    return {
      melding: {
        typeMelding: 0
      }
    }
  },
  mounted() {
    const meldingStore = useMeldingStore();
    this.melding = meldingStore.getCurrentMelding();
  },
  methods: {
    previous() {
      const meldingStore = useMeldingStore();
      meldingStore.moveBackCurrentMelding();
      
      this.$router.push('/add-melding/doelgroep')
    },
    select(leefgebied) {
      const meldingStore = useMeldingStore();
      var currentMelding = meldingStore.getCurrentMelding();
      currentMelding.leefgebied = leefgebied;
      meldingStore.setCurrentMelding(currentMelding);

      this.$router.push('/add-melding/opslaan')      
    }
  }
}
</script>

<style scoped lang="less">
@import "../styles/variables.less";

.navbar {
  .navbar-nav {
    padding-bottom: calc(env(safe-area-inset-bottom) + 26px);
  }
}

@media only screen and (max-height: 550px) {
  .list-group {
    font-size: 0.75rem;
  }
}
</style>