<template>
  <div class="container-fluid">
    <header class="row align-items-center justify-content-center justify-content-md-between">
      <div  class="col-3">
        <a href="#" @click="cancelMelding" v-if="cancelMeldingEnabled && !showLoadingIndicator">Annuleren</a>
        <a href="#" @click="cancelMeldingEdit" v-if="cancelMeldingEditEnabled && !showLoadingIndicator">Annuleren</a>
      </div>
      <div  class="col-6 text-center">
        <h1>{{title}}</h1>
      </div>
      
      <div class="col-3 d-flex flex-row-reverse">
        <a href="#" @click="saveMelding" v-if="saveMeldingEnabled && !showLoadingIndicator">{{saveMeldingCaption}}</a>
        <a href="#" @click="sendMelding" v-if="sendMeldingEnabled && !showLoadingIndicator">{{sendMeldingCaption}}</a>
        <a href="#" @click="saveMeldingEdit" v-if="saveMeldingEditEnabled && !showLoadingIndicator">Opslaan</a>
        <div class="spinner-border spinner-border-sm" role="status" v-if="showLoadingIndicator"></div>
      </div>
    </header>
  </div>
</template>

<script>
import { useMeldingStore } from '@/stores';
import { fetchWrapper } from '@/fetch-wrapper';

export default {
  name: 'HeaderBar',
  props: {
    title: String,
    cancelMeldingEnabled: Boolean,
    saveMeldingEnabled: Boolean,
    cancelMeldingEditEnabled: Boolean,
    saveMeldingEditEnabled: Boolean,
    sendMeldingEnabled: Boolean
  },
  emits: ['saveMelding'],
  data() {
    return {
      location: null,
      gettingLocation: false,
      errorStr: null,
      saveMeldingDisabled: false,
      saveMeldingCaption: 'Opslaan',
      sendMeldingDisabled: false,
      sendMeldingCaption: 'Versturen',
      showLoadingIndicator: false
    }
  },
  methods: {
    cancelMelding() {
      const meldingStore = useMeldingStore();
      meldingStore.cancelCurrentMelding();

      this.saveMeldingDone();
      this.sendMeldingDone();

      this.$router.push('/home/')
    },
    async saveMelding() {
      if (this.saveMeldingDisabled) return;
      
      this.saveMeldingDisabled = true;
      this.showLoadingIndicator = true;

      const meldingStore = useMeldingStore();
      var currentMelding = meldingStore.getCurrentMelding();
           
      try {
        await this.saveMeldingInternal(currentMelding)
      }
      catch (error) {
        this.saveMeldingDone();

        this.$router.push({ name: 'home-with-alert', params: { alert: 'melding-saving-failed' } })
      }
    },
    async saveMeldingInternal(melding) {      
      var body = {
          "Tijdstip": new Date(),
          "Type": melding.typeMelding,
          "Doelgroep": melding.doelgroep,
          "Leefgebied": melding.leefgebied,
          "Opmerking": melding.opmerking,
          "Locatie": {
              "Latitude": melding.position.latitude,
              "Longitude": melding.position.longitude,
          }
      };
      const url = process.env.VUE_APP_API_BASE_URL + '/meldingen';
      try {
        var response = await fetchWrapper.post(url, body);
        const meldingStore = useMeldingStore();
        var currentMelding = meldingStore.getCurrentMelding();
        currentMelding.meldingIdentifier = response.identifier;
        meldingStore.setCurrentMelding(currentMelding);
        
        this.saveMeldingDone();  
      }
      catch (error) {
        console.error('Error:', error);
        this.saveMeldingDone();
      }      

      // Wijkronde or Schoolronde
      if (melding.typeMelding == 10 || melding.typeMelding == 13) {
        this.$router.push({ name: 'home-with-alert', params: { alert: 'melding-saved' } })
      }
      else {
         this.$router.push('/add-melding/opvolgen')
      }
    },
    saveMeldingDone() {
      this.saveMeldingCaption = 'Opslaan';
      this.saveMeldingDisabled = false;

      this.showLoadingIndicator = false;
    },
    async sendMelding() {
      if (this.sendMeldingDisabled) return;

      this.sendMeldingDisabled = true;
      this.showLoadingIndicator = true;

      const meldingStore = useMeldingStore();
      var currentMelding = meldingStore.getCurrentMelding();
           
      try {
        await this.sendMeldingInternal(currentMelding)
      }
      catch (error) {
        this.sendMeldingDone();

        this.$router.push({ name: 'home-with-alert', params: { alert: 'melding-sending-failed' } })
      }
    },
    async sendMeldingInternal(melding) {      
      var body = {
          "MeldingIdentifier": melding.meldingIdentifier,
          "Tijdstip": new Date(),
          "Type": melding.typeMelding,
          "Doelgroep": melding.doelgroep,
          "Leefgebied": melding.leefgebied,
          "Opmerking": melding.opmerking,
          "Naam": melding.naam,
          "Adres": melding.adres,
          "Locatie": {
              "Latitude": melding.position.latitude,
              "Longitude": melding.position.longitude,
          }
      };
      const url = process.env.VUE_APP_API_BASE_URL + '/meldingen/send';
      try {
        await fetchWrapper.post(url, body);   

        this.showAlertBar = false
        
        this.sendMeldingDone();
      }
      catch (error) {
        this.sendMeldingDone();

        console.error('Error:', error);
      }      

      this.$router.push({ name: 'home-with-alert', params: { alert: 'melding-send' } })
    },
    sendMeldingDone() {
      this.sendMeldingCaption = 'Versturen';
      this.sendMeldingDisabled = false;
      
      this.showLoadingIndicator = false;
    },
    cancelMeldingEdit() {
      this.$router.push('/melding-detail/' + 0)
    },
    async saveMeldingEdit() {
      if (this.saveMeldingEditDisabled) return;
      
      this.saveMeldingEditDisabled = true;
      this.showLoadingIndicator = true;

      const meldingStore = useMeldingStore();
      var currentMelding = meldingStore.getSelectedMelding();
           
      try {
        await this.saveMeldingEditInternal(currentMelding)
      }
      catch (error) {
        this.saveMeldingEditDone();

        this.$router.push({ name: 'today', params: { alert: 'melding-saving-failed' } })
      }
    },
    async saveMeldingEditInternal(melding) {      
      var body = {
          "MeldingIdentifier": melding.meldingIdentifier,
          "Opmerking": melding.opmerking
      };
      const url = process.env.VUE_APP_API_BASE_URL + '/meldingen';
      try {
        await fetchWrapper.put(url, body);
        
        this.saveMeldingEditDone();  
      }
      catch (error) {
        console.error('Error:', error);
        this.saveMeldingEditDone();
      }      

      this.$router.push({ name: 'today', params: { alert: 'melding-saved' } })
    },
    saveMeldingEditDone() {
      this.saveMeldingEditDisabled = false;

      this.showLoadingIndicator = false;
    },
    showAlert(message) {
      this.alertMessage = message
      this.showAlertBar = true
      
      this.showLoadingIndicator = false;
    }
  }
}
</script>

<style scoped lang="less">
h1 {
  margin: 0;
}

a {
  color: #fff
}

.spinner-border {
  color: #fff;
  --bs-spinner-border-width: 0.15em;
}
</style>
